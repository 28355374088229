import React from "react";
import { graphql } from "gatsby"

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import SEO from "../components/seo";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import BlogCard from "@/components/blog-card";
import MenuContextProvider from "@/context/menu-context";
import HeaderTwo from "@/components/header-two";
import BlogSidebar from "@/components/blog-sidebar";

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes;
  const tags = data.allMarkdownRemark.group;


  const title = `Blog`;
  return (
    <MenuContextProvider>
      <Layout PageTitle={title}>
        <SEO title={title} />
        <HeaderTwo />
        <PageBanner title="Blog" />
        <div className="commonSection blogPage">
          <Container>
            <Row>
              <Col lg={12} sm={8} >
                <div className="commonSection text-center">
                  <h1 className="sec_title">Our Blog</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={8} sm={8}>
                <Row>
                  {posts.map((post, index) => {
                    const title = post.frontmatter.title || post.fields.slug
                    return (
                      <Col lg={6} sm={12} md={6} key={post.fields.slug}>
                        <article itemScope itemType="http://schema.org/Article">
                          <BlogCard post={post} />
                        </article>
                      </Col>
                    )
                  })}
                </Row>
                 {/* Pagination */}
              </Col>
              <Col lg={4} sm={4} className="blog-sidebar">
                <BlogSidebar data={ tags }/>
              </Col>
            </Row>
           
          </Container>
        </div>
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`